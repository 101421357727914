/* Sidebar base styles */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #333;
    transition: width 0.3s ease;
    overflow: hidden;
    position: fixed;
  }
  
  /* Collapsed state */
  .sidebar.collapsed {
    width: 80px;
  }
  
  /* Expanded state */
  .sidebar.expanded {
    width: 250px;
  }
  
  /* Menu item styles */
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    padding: 15px;
    color: white;
    cursor: pointer;
  }
  
  .sidebar ul li:hover {
    background-color: #444;
  }