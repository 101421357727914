/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.active_inplay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-right: 13px solid transparent;
  border-top: 13px solid red;
  /* animation: 1s infinite blink; */
}

@keyframes blink {
  0% {
    border-top-color: green;
  }

  50% {
    border-top-color: red;
  }

  100% {
    border-top-color: green;
  }
}

@media (max-width: 600px) {
  .edit-columns {
    display: block;
  }
}

.mobile_view_btn{
  display: none;
}

@media (max-width: 800px) {
  .dash_table tbody tr {
    display: flex;
    flex-direction: column;
  }
  .dash_table tbody tr:nth-child(odd) {
   background-color: #EFF1F7;
  }
  .dash_table tbody tr td:nth-child(2) {
   display: none;
  }

  .dash_table tbody tr .oddsBtn {
    width: 100%;
  }
  .dash_table tbody tr .oddsBtn button {
    width: 100% !important;
    height: 30px !important;
    vertical-align: middle;
    line-height: 12px;
  }
  .dash_table tbody tr td {
   border: none;
  }
  .dash_table tbody tr .oddsBtn .btn-group {
    width: 33.3% !important;
  }
  /* .dash_table tbody tr td:nth-child(2) {
    display: none;
  } */
  .series_name_mob{
    display: inline-block;
  }
  .match_name{
    text-align: center;
    width: 88% !important;
    line-height: 22px;
  }
  .mobile_view_btn{
   top: 0;
   right: 0;
   display: block;
  }
  .date_desk{
    display: block;
  }
  .font_small{
    font-size: 15px !important;
  }
  .link_desk{
    display: none;
  }
}


.custom-bottom {
  z-index: 999;
  left: -50px;
}

.loginForm {
  display: flex;
}

.loginLogo {
  display: none;
}

@media (max-width: 768px) {
  .logo-dark {
    display: flex !important; /* Show the logo */
  }

  .loginForm {
    display: none;
  }
  .loginLogo {
    display: inline;
  }
}

::-webkit-scrollbar {
  display: none;
}