// 
// _table.scss
// 

.table {
    >thead {
        border-color: var(--#{$prefix}table-border-color);
        th {
            padding: 0.75rem $table-cell-padding-x;
        }
    }

    >:not(:first-child) {
        border-top-width: $table-border-width;
    }
}


.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.table-sticky {
    th,
    td {
        &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;
            background: var(--tb-card-bg);
            @media screen and (max-width: 540px) {
                position: static;
            }
        }
    }
    th {
        &:first-child {
            z-index: 3;
        }
    }
}
.table-sticky-second {
    tr {
        th,
        td {
            &:nth-of-type(2) {
                position: sticky;
                left: 0;
                z-index: 2;
                background: var(--tb-card-bg);
                &.sticky-active {
                    box-shadow: inset -1px 0px 0px 0px #dde1ef;
                    @media screen and (max-width: 540px) {
                        box-shadow: none;
                    }
                }
                @media screen and (max-width: 540px) {
                    position: static;
                }
            }
        }
        th {
            &:nth-of-type(2) {
                z-index: 3;
            }
        }
    }
}
.table-sticky-head {
    tr {
        th {
            &:nth-of-type(2) {
                background: var(--tb-table-bg);
            }
        }
        td {
            &:nth-of-type(2) {
                background: var(--tb-card-bg);
            }
        }
    }
}
// .table-sticky-last {
//     tr {
//         th,
//         td {
//             &:last-child {
//                 position: sticky;
//                 right: 0;
//                 z-index: 2;
//                 @media screen and (max-width: 540px) {
//                     position: static;
//                 }
//             }
//         }
//         th {
//             &:last-child {
//                 z-index: 3;
//             }
//         }
//     }
// }

// card table

.table-card {
    margin: (-$card-spacer-y) (-$card-spacer-x);

    th,
    td {

        &:first-child {
            padding-left: var(--#{$prefix}card-spacer-x);
        }

        // &:last-child {
        //     padding-right: var(--#{$prefix}card-spacer-x);
        // }
    }

    .table> :not(:first-child) {
        border-top-width: $table-border-width;
    }
}

.table {
    .form-check {
        padding-left: 0px;
        margin-bottom: 0px;

        .form-check-input {
            margin-left: 0px;
            margin-top: 0px;
            float: none;
            vertical-align: middle;
        }
    }

    .sort {
        position: relative;
        padding-right: calc(#{$table-cell-padding-x} * 2.25);

        &::before {
            content: "\f035d";
            position: absolute;
            right: 0.5rem;
            top: 16px;
            font-size: 0.8rem;
            font-family: "Material Design Icons";
        }

        &::after {
            position: absolute;
            right: 0.5rem;
            content: "\f0360";
            font-family: "Material Design Icons";
            font-size: 0.8rem;
            top: 10px;
        }
    }
}

.table-custom-effect {
    tr {
        border-left: 2px solid transparent;
        &.table-active{
            border-left-color: $primary;
        }
    }
}

@each $state in map-keys($theme-colors) {
    .border-#{$state} {
        &.table {
            >thead {
                border-color: var(--#{$prefix}#{$state}) !important;
                border-bottom-color: var(--#{$prefix}#{$state}) !important;
            }
        }
    }
}

/* ===== Dark Scrollbar CSS ===== */
/* Firefox */
[data-bs-theme="dark"] {
    *::-webkit-scrollbar-track {
        background: var(--#{$prefix}secondary-bg);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--#{$prefix}light);
        border: 3px solid var(--#{$prefix}secondary-bg);
    }
}

.table-custom {
    border-collapse: separate;
    border-spacing: 0 10px;

    tr {
        background-color: var(--#{$prefix}secondary-bg);
    }
}


//React Table

.react-table{
    div > input {
        padding: 5px;
        border: 1px solid var(--#{$prefix}border-color);
        outline: 0;
        box-shadow: none;
        border-radius: $border-radius;
        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}