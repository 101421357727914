//
// _authentication.scss
//

.auth-card {
    .auth-effect-main {
        @media (min-width: 576px) {
            width: 444px;
            height: 444px;
            border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

            .effect-circle-1 {
                width: 396px;
                height: 396px;
                border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

                .effect-circle-2 {
                    width: 348px;
                    height: 348px;
                    border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

                    .effect-circle-3 {
                        width: 300px;
                        height: 300px;
                        border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
                    }
                }
            }
        }

        @media (max-width: 800px) {
            width: 300px;
            height: 300px;
            border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

            .effect-circle-1 {
                width: 250px;
                height: 250px;
                border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

                .effect-circle-2 {
                    width: 200px;
                    height: 200px;
                    border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);

                    .effect-circle-3 {
                        width: 150px;
                        height: 150px;
                        border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
                    }
                }
            }
        }

        .auth-user-list {
            li {
                position: absolute;

                &:nth-child(1) {
                    bottom: 82px;
                    left: 48px;
                    @media screen and (max-width:800px) {
                        display: none;
                    }
                }

                &:nth-child(2) {
                    top: 95px;
                    left: 66px;
                    @media screen  and (max-width:800px) {
                        top: 50px;
                        left: 22px;
                    }
                }

                &:nth-child(3) {
                    top: 20px;
                    right: 98px;
                    @media screen and (max-width:800px) {
                        top: 42px;
                        right: 30px;
                    }
                }

                &:nth-child(4) {
                    bottom: -25px;
                    right: 144px;
                    @media screen and (max-width:800px) {
                        bottom: 50px;
                        right: 228px;
                    }
                }

                &:nth-child(5) {
                    bottom: 185px;
                    right: 35px;
                    @media screen and (max-width:800px) {
                        bottom: 55px;
                        right: 13px;                
                    }
                }
            }
        }
    }
}

.signin-other-title {
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed var(--#{$prefix}border-color);
        top: 10px;
    }

    .title {
        display: inline-block;
        position: relative;
        z-index: 2;
        background-color: var(--#{$prefix}secondary-bg);
        padding: 2px 16px;
    }
}

// auth-pass-inputgroup
.auth-pass-inputgroup {
    input[type="text"]+.btn .ri-eye-fill {
        &:before {
            content: "\ecb6";
        }
    }
}

//passowrd validations 
#password-contain {
    display: none;

    p {
        padding-left: 13px;

        &.valid {
            color: $success;

            &::before {
                position: relative;
                left: -8px;
                content: "✔";
            }
        }

        &.invalid {
            color: $danger;

            &::before {
                position: relative;
                left: -8px;
                content: "✖";
            }
        }
    }
}

.password-addon {
    z-index: 5;
}


.desk_view{
    @media screen and (max-width:800px) {
        display: none;
    }
}

.match_highlight{
    background-color: #3eb200;
    color: #3eb200;
    position: relative;
    width: 62px;
    display: block;
    height: 28px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    margin-right: 10px;
    text-transform: uppercase;
    &::before, &::after{
            animation-delay: -4s;
            content: "";
            z-index: 0;
            margin: -5%;
            box-shadow: inset 0 0 0 2px;
            border-radius: 7px;
            animation: clipMe 8s linear infinite;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
    }
    .animate {
        overflow: hidden;
        position: relative;
        float: right;
        width: 73px;
        height: 40px;
        left: 7px;
        top: -4px;
        .play {
            color: #fff;
            font-size: 9px;
            font-weight: 700;
            padding: 0;
            height: 39px;
            margin-bottom: 20px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        }
    }
}

@keyframes clipMe {
    0%, 100% {
        clip: rect(0, 75px, 2px, 0);
    }
    
    25% {
        clip: rect(0, 2px, 75px, 0);
    }
    50% {
        clip: rect(45px, 75px, 75px, 0);
    }
    75% {
        clip: rect(0, 75px, 75px, 45px);
    }
}